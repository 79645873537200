<template>
  <v-app>
    <v-main class="ma-2">
      <map-card />
    </v-main>
  </v-app>
</template>

<script>
import MapCard from "@/components/Cards/MapCard";
import axios from 'axios'
import 'leaflet/dist/leaflet.css';
axios.defaults.withCredentials = true;
axios.defaults.credentials = "include";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default {
  name: 'App',
  components: {
    MapCard
  },
  props: ["lang"],
  data () {
    return {
      language: null
    }
  },
  methods: {
    parse_parks() {
      for (const park of this.$store.state.raw_data) {
        this.$store.state.parks.push({name: park.name, lat: park.latitude, lon: park.longitude});
      }
    }
  },
  mounted() {
     axios.get("https://api.charge.re/public/1/map_parks/"
     ).then(response => {
           this.$store.state.raw_data = response.data;
           this.parse_parks();
   });
    let check = false;
    for (const l of this.$store.state.langs) {
      if (this.lang === l) {
        check = true;
        this.language = this.lang;
        break;
      }
    }
    if (!check) {
      this.language = "fr";
    }
    this.$i18n.locale = this.language;
  },
};
</script>

<style>


@font-face {
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
  font-family: 'Montserrat', sans-serif;
}

* {
  font-family: 'Montserrat', sans-serif;
}

html { overflow-y: auto }

.rounded-card{
    border-radius:50px;
}

h1, h2, h3 {
  text-align: center;
}

u.custom-underline{
  text-decoration: none;
  box-shadow:
    inset 0 -0.3em #dd716c;
}

</style>
