import Vue from 'vue';
import VueI18n from "vue-i18n";
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);
Vue.use(VueI18n);


export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#dd716c',
                error: colors.blue,
            }
        }
    },
    lang: {
    t: (key, ...params) => VueI18n.t(key, params),
  },
});

