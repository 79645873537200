import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import vueCustomElement from 'vue-custom-element'
import 'leaflet/dist/leaflet.css';


Vue.config.productionTip = false
Vue.config.ignoredElements = ['re-map']
Vue.use(vueCustomElement)
App.store = store
App.vuetify = vuetify
App.i18n = i18n
Vue.customElement('re-map', App)
