import Vue from 'vue'
import VueI18n from 'vue-i18n'
import it from './locales/it.json'
import fr from './locales/fr.json'
import en from './locales/en.json'

Vue.use(VueI18n)

const messages = {
  it,
  fr,
  en
};

export default new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages,
})
