<template>
  <v-card
      class="rounded-xl" height="100%">
    <l-map style="height: 100%"
        :zoom="zoom" :center="center" :minZoom="3.0"
        :maxBounds="maxBounds" :maxBoundsViscosity="1.0">
      <l-tile-layer :url="url" :noWrap="true"></l-tile-layer>
        <l-marker v-for="park in $store.state.parks" :lat-lng="[park.lat, park.lon]" :key=park.name>
          <l-popup>
            <div style="text-align: center">
              <p><strong>{{capitalize(park.name)}}</strong></p>
              <a :href="get_geo(park.lat,park.lon)" target="_blank" >
                {{$t('open')}}
              </a>
            </div>
          </l-popup>
        </l-marker>
    </l-map>
  </v-card>
</template>

<script>

// import {LatLng} from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';

import { Icon, latLngBounds } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  name: "MapCard",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  props: ['card_height'],
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 6,
      center: [47.639999, 6.850000],
      bounds: latLngBounds([
        [-90, -180],
        [90, 180]
      ]),
      maxBounds: latLngBounds([
        [-90, -180],
        [90, 180]
      ])
    }
  },
  methods: {
    capitalize(str) {
      var separateWord = str.toLowerCase().replaceAll('_', ' ').split(' ');
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
        separateWord[i].substring(1);
      } 
      return separateWord.join(' '); 
    },
    get_geo(lat, lon) {
      return "https://maps.google.com/?q=" + lat + "," + lon;
    }
  }
}
</script>


<style scoped>
p.big {
  font-size: 3rem;
}
p.smallerer {
  font-size: 1.5rem;
}
p.a {
  font-size: 0.8rem;
}
.rounded-card{
    border-radius:50px;
}
u.custom-underline{
  text-decoration: none;
  box-shadow:
    inset 0 -0.3em #dd716c;
}

@import '~leaflet/dist/leaflet.css';

</style>
